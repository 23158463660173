import request from '@/utils/request'

// 发送email
export const send = data => request({
    url: '/api/v1/email/send',
    method: 'post',
    data
})

// 登录
export const login = data => request({
    url: '/api/v1/user/login',
    method: 'post',
    data
})

// 获取⽤户⾃⼰信息
export const get = data => request({
    url: '/api/v1/user/get',
    method: 'post',
    data
})

// 忘记密码
export const forgetLoginPassword = data => request({
    url: '/api/v1/user/forgetLoginPassword',
    method: 'post',
    data
})

// ⽤户列表
export const list = data => request({
    url: '/api/v1/user/list',
    method: 'post',
    data
})

// 创建⽤户
export const create = data => request({
    url: '/api/v1/user/create',
    method: 'post',
    data
})

// 编辑⽤户
export const edit = data => request({
    url: '/api/v1/user/edit',
    method: 'post',
    data
})

// 创建候选⼈
export const candidates_create = data => request({
    url: '/api/v1/candidates/create',
    method: 'post',
    data
})

// 删除候选⼈
export const candidates_delete = data => request({
    url: '/api/v1/candidates/delete',
    method: 'post',
    data
})

// 候选⼈详情
export const candidates_get = data => request({
    url: '/api/v1/candidates/get',
    method: 'post',
    data
})

// 编辑候选⼈
export const candidates_edit = data => request({
    url: '/api/v1/candidates/edit',
    method: 'post',
    data
})

// 候选⼈列表
export const candidates_list = data => request({
    url: '/api/v1/candidates/list',
    method: 'post',
    data
})

// 创建⼯单
export const workOrder_create = data => request({
    url: '/api/v1/workOrder/create',
    method: 'post',
    data
})

// 获取⼯单信息
export const workOrder_get = data => request({
    url: '/api/v1/workOrder/get',
    method: 'post',
    data
})

// ⼯单列表
export const workOrder_list = data => request({
    url: '/api/v1/workOrder/list',
    method: 'post',
    data
})

// 修改⾯试时间
export const modifyInterviewTime = data => request({
    url: '/api/v1/workOrder/modifyInterviewTime',
    method: 'post',
    data
})

// 修改⼿机号
export const modifyMobile = data => request({
    url: '/api/v1/settings/modifyMobile',
    method: 'post',
    data
})

// 修改登录密码
export const modifyLoginPassword = data => request({
    url: '/api/v1/settings/modifyLoginPassword',
    method: 'post',
    data
})

// 候选⼈对话历史
export const call_list = data => request({
    url: '/api/v1/call/list',
    method: 'post',
    data
})

// 修改登录邮箱
export const modifyLoginEmail = data => request({
    url: '/api/v1/settings/modifyLoginEmail',
    method: 'post',
    data
})

// 修改名称
export const modifyName = data => request({
    url: '/api/v1/settings/modifyName',
    method: 'post',
    data
})

// 验证登录密码
export const verifyPassword = data => request({
    url: '/api/v1/settings/verifyPassword',
    method: 'post',
    data
})

// 编辑⼯单
export const workOrder_edit = data => request({
    url: '/api/v1/workOrder/edit',
    method: 'post',
    data
})

// 是否参加⾯试⾯试
export const isAttend = data => request({
    url: '/api/v1/candidatesapi/isAttend',
    method: 'post',
    data
})

// 终⽌⾯试安排
export const cancelSchedule = data => request({
    url: '/api/v1/workOrder/cancelSchedule',
    method: 'post',
    data
})

// 终⽌⾯试
export const cancel = data => request({
    url: '/api/v1/workOrder/cancel',
    method: 'post',
    data
})

// 开启⼯单
export const start = data => request({
    url: '/api/v1/workOrder/start',
    method: 'post',
    data
})

// 开启前编辑⼯单
export const editNotStart = data => request({
    url: '/api/v1/workOrder/editNotStart',
    method: 'post',
    data
})

// 开启后编辑⼯单
export const editAfterStart = data => request({
    url: '/api/v1/workOrder/editAfterStart',
    method: 'post',
    data
})

// 面试官是否同意候选人修改时间
export const isAggreeChangeTime = data => request({
    url: '/api/v1/interviewer/isAggreeChangeTime',
    method: 'post',
    data
})

// 管理员重设登录密码
export const adminResetLoginPwd = data => request({
    url: '/api/v1/user/adminResetLoginPwd',
    method: 'post',
    data
})

// 重启面试
export const restartCandidate = data => request({
    url: '/api/v1/workOrder/restartCandidate',
    method: 'post',
    data
})

// 面试暂停操作
export const pauseCandidate = data => request({
    url: '/api/v1/workOrder/pauseCandidate',
    method: 'post',
    data
})

// 面试终止操作
export const terminateCandidate = data => request({
    url: '/api/v1/workOrder/terminateCandidate',
    method: 'post',
    data
})

// 获取面试时间信息
export const getWorkOrderTime = data => request({
    url: '/api/v1/interviewer/getWorkOrderTime',
    method: 'post',
    data
})

// 获取面试时间信息
export const c_getWorkOrderTime = data => request({
    url: '/api/v1/candidatesapi/getWorkOrderTime',
    method: 'post',
    data
})

// 添加面试时间信息
export const addInterviewTime = data => request({
    url: '/api/v1/interviewer/addInterviewTime',
    method: 'post',
    data
})
